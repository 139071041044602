//disable both on mobile
.iuu-modal-shadow,
.iuu-modal {
  /* Mobile Only ==============================*/
  @media (max-width: 700px) {
  }
}

.prevent-scroll {
}

.iuu-modal {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  i {
    position: absolute;
    top: -30px;
    right: -30px;
    font-size: 2.8rem;
    color: white;
  }

  img {
    width: auto;
    height: 50vh;
    background-size: contain;
  }

  h1,
  p {
    margin-bottom: 2rem;
    text-align: center;
  }

  h1 {
    font-size: 2rem;
    color: $iuu-black;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.3rem;
    color: lighten($iuu-black, 20%);
  }

  .modal-button {
    color: white;
    text-align: center;
    padding: 1rem;
    font-size: 1.2rem;
    background-color: $iuu-cta;
    max-width: 200px;
    border-radius: 5px;
  }

  &.iuu-modal-small {
    background-color: white;
    max-width: 460px;
    border-radius: 5px;
    height: auto;
    max-height: 40vh;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    * {
      flex: 100%;
    }
  }

  &.iuu-modal-message {
    background-color: white;
    width: 30vw;
    border-radius: 5px;
    border: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .iuu-modal-content {
    background-color: white;
    width: 60vw;
    border-radius: 5px;
    border: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-height: 80vh;
    overflow: auto;
  }
}

.iuu-modal-shadow {
  z-index: 1;
  background-color: $iuu-dark;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .modal-left-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    flex: 1;
    box-sizing: border-box;
    margin: 2.5% auto;

    .modal-greeting {
      font-size: 2rem;
    }

    & > * {
      margin: 10px;
    }

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }

    label,
    input {
      font-size: 1.5rem;
    }

    label {
      margin-top: 0.5rem;
    }

    span,
    button,
    ul {
      font-size: 1.3rem;
    }

    button {
      width: 95%;
      margin: 10px 0;
    }
  }

  .modal-right-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    background: #33b398;
    padding: 2rem;
    color: #fff;
    text-align: center;
    font-size: 1.2rem;

    .modal-title {
      font-weight: 800;
      font-size: 2.5rem;
      letter-spacing: 1px;
    }

    span,
    button {
      font-size: 1.3rem;
    }

    & > * {
      margin: 10px;
    }
  }
}

.modal-content[disabled] {
  opacity: 0.7;
  pointer-events: none;
}

.modal-content-notif {
  width: 50%;
  display: flex;
  flex-direction: column;

  .notif-content-title {
    font-size: 1.5rem;
    font-weight: 800;
  }

  .notif-container {
    display: flex;
    margin: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 5px;
    cursor: pointer;
    overflow: auto;

    &:hover {
      background: rgba(95, 228, 217, 0.2);
    }
  }

  .notif-img {
    border-radius: 3px;
    background: black;
    padding: 10px;
    color: #fff;
    margin-right: 2%;
  }

  .notif-info {
    display: flex;
    flex-direction: column;

    & span:first-child {
      font-weight: 800;
      font-size: 1.1rem;
    }

    & span:last-child {
      color: rgba(0, 0, 0, 0.5);
    }

    i {
      position: unset;
      font-size: unset;
      color: #000;
    }
  }
}

.btn-curve {
  border-radius: 20px;
  border: 1.2px solid #fff;
  color: #fff;
  width: 80%;
}

.green {
  background-color: #31b594;
}

/**** Loading Modal *****/
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(56, 207, 237, 0.2);
  border-right: 1.1em solid rgba(56, 207, 237, 0.2);
  border-bottom: 1.1em solid rgba(56, 207, 237, 0.2);
  border-left: 1.1em solid #38cfed;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  transform: scale(0);
  opacity: 1;
  transition: transform 500s ease-in;
}

.show-loader {
  transform: scale(1);
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.sign-up-container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  flex: 1;
  box-sizing: border-box;
  margin: 2.5% auto;

  .sign-up-greeting {
    justify-self: flex-start;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .btn-container {
    flex-basis: 20%;
    display: flex;
    flex-wrap: wrap;

    .btn-previous {
      width: 0;
      display: none;
    }

    .btn-next {
      flex-basis: 70%;
      flex-grow: 1;
    }
  }

  label,
  input {
    font-size: 1.5rem;
    margin: 0.5rem 0 0 0;
  }

  span,
  button,
  ul {
    font-size: 1.3rem;
  }

  #organization-toggle {
    margin: 0 0.5rem;
  }
}

.show-button {
  display: block;
  width: 200px;
}

.content-form {
  display: none;
  margin: 10px 0;
}

.content-form[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
.success-list {
  display: none;
  border: 1px solid #3c763d;
  border-radius: 5px;
  padding: 10px;
}

.error-list {
  display: none;
  border: 1px solid rgba(199, 42, 42, 1);
  border-radius: 5px;
  padding: 10px;
}

.error-item {
  margin: 0;
  color: rgba(199, 42, 42, 1);
}

.success-item {
  margin: 0;
  color: #3c763d;
}

.btn-next i {
  position: unset;
  float: right;
  font-size: 1.5rem;
}

.btn-activate {
  opacity: 0.5;
  pointer-events: none;
}

.form-activate {
  display: block;
}

.msg-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo-modal {
    width: 50px;
    height: 50px;
  }

  button {
    margin-top: 1em;
    border-radius: 20px;
    border: 1.2px solid #fff;
    color: #fff;
    width: 100%;
  }

  h1 {
    margin: 0;
  }

  & > * {
    margin: 5px;
  }

  label,
  input {
    font-size: 1.5rem;
    margin: 0.5rem 0 0 0;
  }

  span,
  button,
  ul {
    font-size: 1.3rem;
  }
}

.request-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > * {
    margin: 5px 0;
  }

  i {
    position: unset;
    font-size: unset;
    float: right;
    color: #000;
  }
}

.org-divider {
  display: flex;
  margin: 5px 0;
  align-items: center;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
}

/** Toggle button **/
input[type="checkbox"] {
  &.toggle {
    opacity: 0;
    position: absolute;
    left: -99999px;

    & + label {
      height: 40px;
      line-height: 40px;
      background-color: #ccc;
      padding: 0px 16px;
      border-radius: 16px;
      display: inline-block;
      position: relative;
      cursor: pointer;
      @include transition(all 0.25s ease-in);
      @include box-shadow(inset 0px 0px 2px rgba(0, 0, 0, 0.5));

      &:before,
      &:hover:before {
        content: " ";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 46px;
        height: 36px;
        background: #fff;
        z-index: 2;
        @include transition(all 0.25s ease-in);
        @include border-radius(14px);
      }

      .off,
      .on {
        color: #fff;
      }

      .off {
        margin-left: 46px;
        display: inline-block;
      }

      .on {
        display: none;
      }
    }

    &:checked + label {
      .off {
        display: none;
      }
      .on {
        margin-right: 46px;
        display: inline-block;
      }
    }

    &:checked + label,
    &:focus:checked + label {
      background-color: #67a5ec;

      &:before,
      &:hover:before {
        background-position: 0 0;
        top: 2px;
        left: 100%;
        margin-left: -48px;
      }
    }
  }
}


.new-modal-content {
    background-color: white;
    width: 60vw;
    border-radius: 5px;
    border: 0;
    width: 800px;
    max-width: 800px;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    text-align: center;
    padding: 2.5em;
    background-image: url("../static/images/page-background-island.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .modal-logo {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }

    .context-text {
        margin-top: 1em;
        font-size: 3em;
        font-weight: 800;
        max-width: 400px;
        letter-spacing: 1px;
        color: #fff;
        text-shadow: white 0px 0px 5px;
    }
}

.close-btn {
    cursor: pointer;
}

@media screen and (max-width: 1100px) {
    .modal-content {
      flex-direction: column;
    }
  
    .modal-right-info {
      padding: 2.5% auto;
    }
  
    #root {
      .iuu-modal-message {
        width: 40vw;
      }
    }

    .new-modal-content {
        width: 100vw;
        max-width: 100vw;
    }

    .close-btn {
        right: 10px !important;
        top: -40px !important;
    }
}
  
  @media screen and (max-width: 900px) {
    #root {
      .iuu-modal-message {
        width: 55vw;
      }
    }
  }
  
  @media screen and (max-width: 700px) {
    #root {
      .iuu-modal-content {
        width: 80vw;
      }
  
      .iuu-modal-message {
        width: 70vw;
      }
    }
  }