.vessel {
  .vessels-info-row {
    margin-right: 0;
    margin-left: -18px;
  }

  .banner {
    width: 100%;
    margin: -3px 0 0 0;
    position: relative;
    margin-top: 5rem;
    position: relative;

    .mobile-banner-text {
      color: white;
      display: block;
      text-align: center;
      font-size: 3rem;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translateX(-50%);
      font-weight: bold;
    }

    /* Desktop Only ==============================*/
    @media (min-width: 700px) {
      .mobile-banner-text {
        display: none;
      }
    }
  }
  .banner img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 32vh;
  }
  a.back-btn {
    color: white;
    font-weight: bold;
    font-size: 0.9rem;
    position: absolute;
    top: 32px;
    left: 30px;
    font-size: 1.2rem;
    background-color: $iuu-dark;
    border-radius: 10px;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    padding-top: 0.1rem;
  }
  a.back-btn i {
    margin: 0 5px 0 0;
    font-weight: 600;
  }

  .middle-sec {
    // * {
    //   max-height: 55vh;
    // }

    .incident-content {
      height: 100%;
    }
  }

  .middle-sec h3 {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 1px;
    padding: 1.5rem;
    background: $iuu-dark-blue;
    margin-bottom: 0;
  }
  .p0 {
    padding: 0;
  }
  .nav-pills {
    background: #182832;

    /* Desktop Only ==============================*/
    @media (min-width: 700px) {
      min-height: 60vh;
      height: 100%;
    }
  }

  .nav-link.active,
  .nav-pills a {
    padding-left: 3.7rem !important;
  }

  .nav-pills a {
    font-size: 1.4rem;
    color: #fff;
    padding: 15px 20px;
    letter-spacing: 1px;
    border-radius: 0 !important;
    position: relative;
    font-weight: 600;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;

    /* Mobile Only ==============================*/
    @media (max-width: 700px) {
      padding: 0.8rem;
    }
  }
  .nav-pills .nav-link.active::after {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-left: 30px solid #4c677a;
    border-bottom: 30px solid transparent;
    position: absolute;
    right: -30px;
    top: 0;
    content: "";
  }
  .nav-pills a:hover {
    background: #4c677a;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #4c677a;
    padding: 1.9rem;
    font-size: 1.4rem;

    /* Mobile Only ==============================*/
    @media (max-width: 700px) {
      padding: 0.8rem;
    }
  }

  .tab-content {
    padding: 30px 25px;
  }

  .flag-table {
    margin: 0;
    padding: 0;
  }
  .flag-table .table-heading h3 {
    font-weight: 700;
    color: #000;
    letter-spacing: 1px;
    padding: 20px 30px;
    background-color: #f9f9f9;
    border-bottom: 5px solid #4c677a;
  }
  .flag-table .flag-table-details table {
    border-collapse: collapse;
    width: 100%;
  }

  .footer-label {
    font-size: 1.2rem;
    text-align: left;
    font-weight: bold;
    color: silver;
  }

  .flag-table {
    /* Mobile Only ==============================*/
    @media screen and (max-width: 700px) {
      tr,
      th,
      td {
        font-size: 1.2rem;
      }
    }

    /* Desktop Only ==============================*/

    @media screen and (min-width: 700px) {
      tr,
      th,
      td {
        font-size: 1.5rem;
      }
    }
  }

  .mobile-table-container {
    /* Mobile Only ==============================*/
    @media screen and (max-width: 700px) {
      padding-right: 0 !important;
    }
  }

  .flag-table .flag-table-details tr th {
    padding: 15px 30px;
    color: #000;
    letter-spacing: 1px;
    width: 33.3%;
    height: 50px;
    font-weight: 700;
  }
  .flag-table .flag-table-details tr td {
    padding: 15px 30px;

    color: #333;
    letter-spacing: 0px;
    width: 33.3%;
    height: 50px;
    font-weight: 400;
  }
  .bg-color td {
    background-color: #f9f9f9;
    height: 50px;
  }

  @media screen and (max-width: 767px) {
    .nav-pills {
      background: #182832;
      height: auto;
    }
    .nav-pills .nav-link.active::after {
      content: none;
    }
    .tab-content {
      padding: 0px 0px;
    }
    .flag-table .flag-table-details tr th {
      padding: 15px 15px;
    }
    .flag-table .flag-table-details tr td {
      padding: 15px 15px;
    }
    .flag-table .table-heading h3 {
      padding: 20px 15px;
    }
    .middle-sec h3 {
      display: none;
      font-size: 15px;
      padding: 10px 19px;
    }
    .p0 {
      padding: 0 15px;
    }
    .middle-sec .padding {
      padding: 0;
    }
  }

  .basic-info {
    padding: 3rem;

    /* Mobile Only ==============================*/
    @media (max-width: 700px) {
      padding-left: 2rem;
      padding-top: 1.6rem;
    }

    p {
      font-size: 1.4rem;
    }

    h1 {
      font-weight: bold;
      font-size: 2rem;

      /* Mobile Only ==============================*/
      @media (max-width: 700px) {
        font-size: 2rem;
      }
    }

    .incident-title {
      font-size: 2rem;
      font-weight: bold;
    }

    .lists {
      display: flex;
      flex-wrap: wrap;
    }
    .list {
      flex: 0 0 500px;
      margin-bottom: 4rem;
      padding: 0 1.5rem;
      max-width: 100%;

      .item {
        font-size: 1.4rem;
        display: flex;
        padding: 0.5rem;
        justify-content: center;
        align-items: center;

        i {
          flex: 0 0 15px;
        }

        .content {
          flex: 1 1 auto;

          display: flex;
          flex-wrap: wrap;

          .header {
            font-weight: bold;
            color: $iuu-dark;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

/**** Story Tab CSS ****/
.story-wrapper {
  .story-row {
    font-size: 1.5rem;
    display: flex;
    padding: 1%;
    background: rgba(0, 0, 0, 0.1);

    * {
      flex-basis: 33%;
    }

    a {
      cursor: pointer;
      color: rgba(13, 139, 197, 1);
    }
  }

  .story-row:nth-child(odd) {
    background: rgba(0, 0, 0, 0.3);
  }
}
