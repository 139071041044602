.request-info {
  .row {
    margin-bottom: 2rem;
  }

  form {
    max-width: 668px;
    margin: 0 auto;
    font-size: 1.3rem;

    small {
      color: lighten($iuu-black, 30%);
    }

    h2 {
      font-size: 2rem;
    }
  }
}
