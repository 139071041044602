.about {
  padding-bottom: 5rem;

  max-width: 50vw;
  margin: 6rem auto;

  /* Mobile Only ==============================*/
  @media (max-width: 700px) {
    max-width: 80vw;
  }

  .ecotrust-logo {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 850px;
    margin: 0 auto;
    margin-bottom: 2rem;

    /* Mobile Only ==============================*/
    @media (max-width: 700px) {
      justify-content: center;
    }

    img {
      flex: 0 0 50%;
      max-width: 250px;
      width: 100%;

      /* Mobile Only ==============================*/
      @media (max-width: 700px) {
        object-fit: scale-down;
      }
    }
    .mission-container {
      flex: 50%;
      display: flex;
      flex-wrap: wrap;

      span {
        padding: 0.6rem;
        text-align: center;
        color: white;
        background-color: #b9bf12;
        height: 27px;
        max-width: 100px;
        margin-left: auto;
      }
      p {
        text-align: right;
        font-weight: bold;
        font-size: 1.3rem;
        box-sizing: border-box;
        padding-left: 40%;
      }
    }
  }

  h1,
  h2 {
    max-width: 850px;
    margin: 0 auto;
  }

  h1 {
    font-size: 2.5rem;
    padding: 0;
  }

  h2 {
    text-align: center;
    color: lighten($iuu-dark, 20%);
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 1rem;
  }

  p {
    max-width: 850px;
    margin: 0 auto;
    display: block;
    padding: 2rem;
    font-size: 1.3rem;
    color: #000000b8;
  }

  .offense-categories {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem;
    max-width: 600px;
    margin: auto;
    padding: 2rem;

    .offense {
      flex: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1.5rem;
      margin-top: 0.5rem;
      border-bottom: 1px solid #c0c0c026;

      .offense-title {
        font-weight: bold;
        flex: 0 1 200px;
      }
      .offense-description {
        flex: 1;
      }
      /* Mobile Only ==============================*/
      @media (max-width: 700px) {
        .offense-title,
        .offense-description {
          flex: 100%;
        }
      }
    }
  }

  .bottom-profile {
    padding: 2rem;
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

    img {
      flex: 0 1 140px;
      margin-right: 1rem;
      box-sizing: border-box;
      object-fit: cover;
      height: auto;
    }
    .bottom-profile-description {
      flex: 1;
      h1 {
        font-size: 2rem;
        text-align: left;
      }
      p {
        padding: 0;
        margin: 0;
        margin-top: 2rem;
      }
    }
    /* Mobile Only ==============================*/
    @media (max-width: 700px) {
      img,
      .bottom-profile-description {
        flex: 100%;
      }
    }
  }
}
