#filters {
  color: rgba(255, 255, 255, 0.9);
  width: 288px;
  padding-top: 4rem;
  background-color: #1b1c1d;
  position: fixed;
  height: 100vh;
  transition-property: right;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  overflow-y: auto;

  .filter-wrapper {
    flex: 0 0 100%;
    max-width: 287px;
    position: relative;
    padding-left: 0.9rem;

    .filter-toggle {
      cursor: pointer;
      position: absolute;
      top: 4rem;
      border: 1px solid #cccccc70;
      left: 5px;
      background-color: #0f0f0f;
      border-radius: 100%;
      width: 22px;
      display: flex;
      height: 22px;
      z-index: 1;
      justify-content: center;
      align-items: center;

      i {
        font-size: 1rem;
        color: darken(white, 20%);
        margin-left: -0.1rem;
      }
    }
  }

  .filter-black-divisor {
    width: 80%;
    height: 1px;
    display: block;
    margin: 0.8rem auto 0;
    background-color: $iuu-dark;
  }

  .filter-black-divisor.internal-black-divisor {
    width: 96% !important;
    display: inline-block;
    float: left;
  }

  h2 {
    word-spacing: 3.3px;
    //date range title
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1.7rem;

    /* Mobile Only ==============================*/
    @media (max-width: 700px) {
      margin-bottom: 0.5rem;
    }
  }

  #date_range {
    //date range block
    padding: 44px 30px 50px;

    /* Mobile Only ==============================*/
    @media (max-width: 700px) {
      padding-bottom: 30px;
    }

    //border-bottom: 1px solid #9e9e9e;

    .date-wrapper {
      //from - to wrapper
      background-color: $iuu-dark;
      display: flex;
      justify-content: center;
      border-radius: 23px;
      padding: 7.2px;
      padding-top: 7px;
      padding-bottom: 6px;

      .date-from-to-divisor {
        //line between from and to
        width: 40px;
        height: 1px;
        background-color: $iuu-font-gray;
        margin-right: 0.6rem;
        margin-left: 0.6rem;
        position: relative;
        top: 0.8rem;
      }
    }

    .ui.form .inline.fields .field {
      padding: 0 !important;
      margin-top: -0.2rem;
    }

    #filter-input {
      margin-bottom: 12px;

      font-size: 1.2rem;
      label {
        color: white;
        margin-top: 0rem;
        margin-bottom: 0;
        padding-right: 0.7rem;
        padding-left: 0.2rem;
      }

      .date-input {
        background-color: $iuu-black;
        color: white;
        font-size: 0.65rem;
        border-radius: 14px;
        width: 50px;
        padding: 4px 0 2px 8px;
        height: 19px;
        border: none;
        font-size: 1rem;

        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
      }
    }
  }

  #crime_categories {
    padding: 20px 20px 0 25px;

    /* Mobile Only ==============================*/
    @media (max-width: 700px) {
      padding-top: 10px;
    }

    .radio {
      // margin: 8px 0;
      margin-left: 1rem;
    }

    p {
      word-spacing: 3.3px;
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 2.8rem;
      margin-bottom: 1.7rem;

      /* Mobile Only ==============================*/
      @media (max-width: 700px) {
        margin-top: 1.8rem;
        margin-bottom: 0.7rem;
      }
    }

    label {
      font-weight: normal;
      padding-left: 0.5rem;
      cursor: pointer;
      position: relative;
      margin-left: 8px;
      transition: color 0.2s linear;
      -webkit-transition: color 0.2s linear;
    }

    label:hover {
      color: #d2d2d2;
    }

    /* CUSTOM RADIO BUTTONS */
    input {
      visibility: hidden;
    }

    .check {
      border: 3px solid #b5b5b5;
      border-radius: 100%;
      height: 15px;
      width: 15px;
      position: absolute;
      bottom: 3px;
      left: -0.5rem;
    }

    .check::before {
      display: block;
      position: absolute;
      content: "";
      border-radius: 100%;
      height: 5px;
      width: 5px;
      top: 2px;
      left: 2px;
      margin: auto;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
    }

    label input:checked ~ .check::before {
      background: white;
    }
  }

  .radio {
    label {
      display: flex;
      font-size: 1.3rem;

      .check {
        position: relative;
        top: 2px;
      }
    }
  }

  #crime_dates {
    padding: 1rem 20px 0 25px;

    .radio {
      margin: 8px 0;
      margin-left: 1rem;
    }

    p {
      word-spacing: 3.3px;
      font-weight: 600;
      margin-top: 2.8rem;
      margin-bottom: 1.7rem;
    }

    label {
      font-weight: normal;
      padding-left: 0.5rem;
      cursor: pointer;
      position: relative;
      margin-left: 8px;
      transition: color 0.2s linear;
      -webkit-transition: color 0.2s linear;
    }

    label:hover {
      color: #d2d2d2;
    }

    /* CUSTOM RADIO BUTTONS */
    input {
      visibility: hidden;
    }

    .check {
      border: 3px solid #b5b5b5;
      border-radius: 100%;
      height: 15px;
      width: 15px;
      position: absolute;
      bottom: 3px;
      left: -0.5rem;
    }

    .check::before {
      display: block;
      position: absolute;
      content: "";
      border-radius: 100%;
      height: 5px;
      width: 5px;
      top: 2px;
      left: 2px;
      margin: auto;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
    }

    label input:checked ~ .check::before {
      background: white;
    }
  }

  .filters-bottom-disclaimer {
    align-self: flex-end;
    padding-top: 1rem;
    width: 100%;

    #copyright,
    #about-categories {
      font-size: 1.2rem;
    }

    #copyright {
      p {
        text-align: center;
        margin: 0 -2rem;
        line-height: 1.2;
      }

      margin: 5px 12px;

      color: #7a7a7a;
      max-width: 75%;
      margin: 0 auto;
    }

    #about-categories {
      text-align: center;
      text-decoration: underline;

      a {
        text-decoration: none;
        color: white;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .short-disclaimer {
    padding: 2px;
    margin: 5px 10px;
  }
}
