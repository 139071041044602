nav {
  .logo {
    width: 2.2rem;
    position: relative;
    top: 2px;
  }

  .headline {
    margin-left: 0.8rem;

    .subtitle {
      margin-left: 0.7rem;
      color: darkgray;
    }

  }

  .vessel-search {
    width: 280px;
  }

  /*MOBILE ONLY CODE*/
  @media screen and (max-width: 700px) {
    .subtitle, .vessel-search {
      display: none;
    }
  }


}