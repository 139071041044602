.contact {

  .contact-form {
    max-width: 500px;
    margin: 0 auto;
    font-size: 1.2rem;

    .row {
      margin-bottom: 2rem;
    }
  }


}