.vessel-search-list {
  max-width: 60vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10rem;
  margin: 0 auto;

  .column-left,
  .column-right {
    box-sizing: border-box;
    padding: 0.2rem;
  }

  .column-left {
    flex: 50%;
  }
  .column-right {
    flex: 50%;
  }

  .item {
    /* Mobile Only ==============================*/
    @media (max-width: 700px) {
      flex: 100%;
    }

    flex: 0 0 320px;
    font-size: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 0.5rem;
    justify-content: center;
    text-align: left;
    border-radius: 5px;
    background-color: lighten(silver, 22%);
    padding: 0.5rem;

    &.search-result {
      .truncate {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .image {
      flex: 0 0 27px;
      position: relative;
      top: 2px;
    }
    .content {
      flex: 1 1 auto;
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
}
.basic-info h1 {
  margin-bottom: 1rem;
}
