.iuu-header {
  padding: 10px 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background: #fff;
  position: fixed;
  z-index: 999;
  left: 0;
  width: 100%;
  top: 0;
  background: #fff;
  height: 70px;
  box-shadow: 3px 6px 5px 0px rgba(0, 0, 0, 0.11);

  .iuu-header-row {
    margin-top: -0.5rem;
  }

  .logo {
    img {
      width: 198px;
      position: relative;
      margin-right: 1.9rem;
      top: -3.1px;

      /* Mobile Only ==============================*/
      @media (max-width: 700px) {
        width: 168px;
        position: relative;
        margin-right: 1.9rem;
        top: 0;
      }
    }
  }

  a.logo {
    font-size: 16px;
    color: #222;
    text-decoration: none;
    font-weight: 700;
    padding: 15px 0;
    display: block;
    position: relative;

    .subtitle {
      position: absolute;
      top: 36px;
      left: -5px;
      width: 200px;
      font-weight: normal;
      font-size: 11px;
      margin-left: 0.7rem;
      color: darkgray;
    }
  }

  a.logo i {
    color: #055a6e;
    font-size: 22px;
    vertical-align: middle;
    margin: 0 10px 0 0;
  }

  .closebtn {
    display: none;
  }

  h1.mobile-menu {
    display: none;
  }

  .navigation {
    padding: 0px 0;
    margin-top: 0.6rem;
    flex: 60%;
  }

  .navigation ul {
    list-style: none;
    text-align: right;
    display: flex;
    justify-content: center;
    margin: auto auto;
  }

  .navigation ul li {
    display: inline-block;
    margin: 0 0px;
    position: relative;
  }

  .mob-menu .searchbox {
    float: none;
  }

  .mob-menu ul {
    list-style-type: none;
    text-align: center;
  }

  .mob-menu ul li a {
    color: #222;
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration: none;
  }

  .navigation ul li a {
    color: #222;
    display: block;
    text-transform: uppercase;
    padding: 15px 15px 15px 5px;
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-weight: 600;
    text-decoration: none;
    text-align: left;
  }

  .navigation ul li a:hover {
    color: #055a6e;
  }

  .searchbox-container {
    margin: 0.6rem 10px 0 5px;
    flex-basis: 30%;
    display: flex;
  }

  .searchbox {
    flex-basis: 60%;
  }

  .icon-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-basis: 40%;
    font-size: 1.4rem;
    margin-right: 2%;
    white-space: nowrap;

    & > * {
      margin: 15px 15px 15px 5px;
    }

    .notification-selector {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      user-select: none;

      .notification-icon {
        cursor: pointer;
        font-weight: 800;
        font-size: 1.5rem;

        & > span:first-child {
          position: relative;
          top: -10px;
          right: 4px;
          font-weight: 700;
          color: #fff;
          font-size: 1rem;
          padding: 3px 5px;
          text-align: center;
          background: #f9354c;
          border-radius: 15px;
        }
      }

      .notification-container.active {
        display: block;
        -webkit-transform-origin: right top 0px;
        -webkit-transform: scale(1);
      }
    }

    .btn-login {
      font-size: 1rem;
      margin: 0 0;
      padding: 5px;
    }

    .user-container {
      position: relative;
      right: 10px;
      .btn-user {
        display: flex;
        font-size: 1.8rem;
        color: #055a6e;
        cursor: pointer;
        user-select: none;
        p {
          margin-left: 5px;
          font-size: 1.4rem;

          font-weight: 600;
          text-transform: uppercase;
          color: #000;
          overflow: auto;
        }
      }
      .container-user-menu {
        position: absolute;
        top: 25px;
        right: -10px;
        z-index: 1000;
        font-size: 14px;
        text-align: right;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        background-clip: padding-box;
        padding-top: 10px;
        -webkit-transition: all 0.2s ease;
        -webkit-transform-origin: right top 0px;

        ul {
          list-style: none;
          padding: 2.5px;
          margin: 2px 0px 2px 5px;
          position: relative;

          li {
            text-align: left;
            color: #666;
            padding: 2px 5px;
            padding-right: 10px;
            height: 24px;
            user-select: none;
            cursor: pointer;
          }

          li:hover {
            color: #055a6e;
          }
        }
      }
    }
  }

  .searchbox input.form-control {
    border-bottom: solid 1px #d7d7d7;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0px;
    height: auto;
    box-shadow: none;
    font-size: 1.4rem;
    padding-top: 1rem;

    /* Desktop Only ==============================*/
    @media (min-width: 700px) {
      min-width: 180px;
    }
  }

  .search-container {
    position: relative;
  }

  .searchbox i {
    position: absolute;
    right: 5%;
    top: 15px;
    color: #055a6e;
    cursor: pointer;
  }

  .col-left {
    width: 20%;
  }

  .col-right {
    width: 80%;
  }

  .right-column-header {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }

  .right-column {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .header {
    h1.mobile-menu {
      display: block;
      position: relative;
      top: 0;
      right: 0;
      font-size: 25px;
      cursor: pointer;
      color: #000;
      float: right;
      padding: 10px 0 0px;
      font-weight: 300;
      margin-top: 0.4rem;
    }

    .navigation {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: #000;
      overflow-x: hidden;
      overflow-y: scroll;
      transition: 0.5s;
      padding-top: 60px;
      z-index: 999999;
      overflow-y: scroll;
    }

    .closebtn {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 45px;
      margin-left: 0;
      color: #fff;
      display: block;
      padding: 0;
      text-align: center;
      line-height: 34px;
      font-weight: 300;
      text-decoration: none;

      &:active,
      &:hover {
        text-decoration: none;
      }
    }

    .navigation ul {
      width: 100%;
      float: none;
      padding: 0;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
    }

    .navigation ul li {
      margin: 0px 15px;
      padding: 5px 0;
      display: block;
      text-align: center;
      flex: 100%;
    }

    .navigation ul li a {
      padding: 10px 0;
      color: #fff;
      font-size: 16px;
    }

    a.logo {
      font-size: 14px;
    }

    .searchbox {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    a.logo span {
      display: none;
    }

    a.logo i {
      font-size: 32px;
      margin: 0 0px 0 0;
    }

    a.logo {
      padding: 10px 0;
      margin-top: 0.6rem;
    }

    .nav-pills {
      background: #182832;
      height: auto;
    }

    .nav-pills .nav-link.active::after {
      content: none;
    }
  }
}

/* Mobile Only ==============================*/
@media screen and (max-width: 1100px) {
  .right-column {
    display: none;
  }

  /************* Mobile Menu ******************/
  .mob-menu {
    position: fixed;
    display: block !important;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 2em;
    transform: translateX(-100%);
    transition: transform 0.4s linear;
    z-index: 9999;
  }

  .mob-menu-open {
    transform: translateX(0);
  }

  .mob-menu .language-container {
    left: 0;
    top: -50px;
  }

  .hamburger {
    flex-direction: column;
    cursor: pointer;
    text-align: right;
  }
  .hamburger span {
    display: inline-block;
    width: 22px;
    height: 3px;
    margin: 0.2em 0em;
    background-color: black;
  }

  .right-column-mobile-header {
    display: flex !important;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
  }
  ul.nav-sub-menu li {
    display: block;
  }

  .user-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: auto auto;
    margin-bottom: 2.5%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    & > * {
      margin: 1%;
    }

    .user-img {
      border-radius: 50%;
      background: rgba(54, 212, 212, 0.5);
      padding: 10px;
      color: #fff;
      font-weight: 800;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

/*** Styles for Language Selector Icon ***/

.language-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  .drop {
    border-radius: 100%;
    font-weight: 800;
    font-size: 1.4rem;
    text-decoration: none;
    border: 1px solid rgba(19, 18, 18, 0.5);
    padding: 2px;
    cursor: pointer;
  }

  .fa-globe:before {
    color: #146c78;
  }

  span.active {
    color: #146c78;
  }

  .container-menu {
    position: absolute;
    top: 25px;
    right: -10px;
    min-width: 150px;
    max-width: 150px;
    z-index: 1000;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    padding-top: 10px;
    -webkit-transition: all 0.2s ease;
    display: none;
    -webkit-transform-origin: right top 0px;
    -webkit-transform: scale(0);
  }

  .container-menu ul {
    padding: 2.5px;
    margin: 2px 0 0;
    list-style: none;
    position: relative;
  }

  .container-menu.active {
    display: block;
    -webkit-transform-origin: right top 0px;
    -webkit-transform: scale(1);
  }

  .container-menu li {
    color: #666;
    padding: 2px 0 2px 0;
    height: 24px;
    opacity: 0;
    transform: translateY(0px);
    user-select: none;
    cursor: pointer;
  }

  .container-menu.active li {
    opacity: 1;
    transform: translateY(0px);
  }

  .selected {
    background-color: rgba(54, 212, 212, 0.5);
  }

  .container-menu.active li:hover {
    background-color: rgba(50, 50, 50, 0.5);
  }
}

/*** Styles for Notification Icon ****/

.notification-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: auto;
  top: 25px;
  right: -10px;
  min-width: 350px;
  max-width: 350px;
  max-height: 40vh;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  -webkit-transition: all 0.2s ease;
  display: none;
  -webkit-transform-origin: right top 0px;
  -webkit-transform: scale(0);

  .notif-content-title {
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 1%;
    padding: 1%;
  }

  .notif-container {
    display: flex;
    margin: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 5px;
    cursor: pointer;

    &:hover {
      background: rgba(95, 228, 217, 0.2);
    }
  }

  .notif-img {
    border-radius: 3px;
    background: black;
    padding: 10px;
    color: #fff;
    margin-right: 2%;
  }

  .notif-info {
    display: flex;
    flex-direction: column;

    & span:first-child {
      font-weight: 800;
      font-size: 1.1em;
    }

    & span:last-child {
      color: rgba(0, 0, 0, 0.5);
    }

    i {
      position: unset;
      font-size: unset;
      color: #000;
    }
  }
}
