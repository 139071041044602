/*** EEZ Categories ***/
.subscribe-eez-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: scrollDown 1s ease-in forwards;

  .search-container {
    display: flex;
    width: 100%;
    padding: 0 20%;
  }
  .eez-search {
    flex-basis: 80%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn-eez-search {
    border-left: 0;
    background: #5082b6;
    border-radius: 5px;
    color: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > :last-child {
    margin-top: 2.5%;
    width: 100%;
  }

  @keyframes scrollDown {
    0% {
      opacity: 0.25;
      transform: translateY(50px);
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.eez-container {
  flex-basis: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  list-style-type: none;
  padding: 2px;
  margin: 5px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  line-height: 5px;

  .eez-category {
    counter-increment: step-counter;
    display: flex;
    font-weight: 700;
    flex: 1 0 200px;
    box-sizing: border-box;
    line-height: 10px;
    padding: 10px;
    margin: 2.5px;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s ease-in;

    &::before {
      content: counter(step-counter);
      margin-right: 10px;
      font-size: 80%;
      background-color: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(0, 0, 0, 0.25);
      color: #000;
      font-weight: bold;
      padding: 3px 8px;
    }

    &:hover {
      transform: translateY(1px);
    }
  }
  .eez-selected {
    border: 2px solid rgb(77, 179, 238);
    background: rgba(77, 179, 238, 0.2);
  }
}

.eez-list-edit {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 5px;
  background: #f3f3f3;
  border-radius: 2px;

  .eez-edit-tag {
    position: relative;
    white-space: nowrap;
    background: #28343d;
    padding: 5px 20px 5px 8px;
    border-radius: 2px;
    color: #fff;
    font-size: 1rem;

    &::after {
      font-size: 1.2rem;
      position: absolute;
      right: 5px;
      content: "×";
    }

    &:hover {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      perspective: 1000px;
    }

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }
      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }
      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }
      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }

  & > * {
    margin-right: 10px;
  }
}

.tags-new input[type="text"] {
  border: 0;
  margin: 0;
  padding: 0 0 0 3px;
  font-size: 1.3rem;
  background: transparent;
  font-weight: 600;
}

.tags-new input[type="text"]:focus {
  outline: none;
}

.eez-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.eez-list li,
.eez-list a {
  float: left;
  height: 26px;
  line-height: 24px;
  position: relative;
  color: #fff;
}
.eez-list a {
  margin-left: 20px;
  padding: 0 10px 0 12px;
  background: #0089e0;
  color: #fff;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
.eez-list a:before {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent #0089e0 transparent transparent;
  border-style: solid;
  border-width: 12px 12px 14px 0;
}
.eez-list a:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  float: left;
  width: 4px;
  height: 4px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #fff;
  -moz-box-shadow: -1px -1px 2px #004977;
  -webkit-box-shadow: -1px -1px 2px #004977;
  box-shadow: -1px -1px 2px #004977;
}

.eez-list a:hover {
  background: #555;
}
.eez-list a:hover:before {
  border-color: transparent #555 transparent transparent;
}

.subscriber-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sub-icon {
    font-size: 50px;
  }
}
.subscription-container {
  display: flex;
  flex-direction: column;
}

.account-container {
  .field-column-three {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1%;

    & > * {
      flex: 1 0 300px;
    }
  }

  .info-field {
    display: flex;
    flex-direction: column;
    padding: 2% 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);

    .info-title {
      font-size: 1.5rem;
      font-weight: 800;
    }

    .info-value {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .alert-status {
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-size: 1.5rem;
  }

  .is-verified {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;

    &::before {
      font-family: "FontAwesome";
      font-weight: 100;
      font-size: 2rem;
      content: "\f05a";
      margin: 10px;
    }
  }

  .not-verified {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;

    &::before {
      font-family: "FontAwesome";
      font-weight: 100;
      font-size: 2rem;
      content: "\f12a";
      margin: 10px;
    }
  }

  & > :last-child {
    //margin-top: 2.5%;
  }

  & .info-field:first-child {
  }
  .eez-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    .text-field {
      flex-basis: 85%;
      display: flex;
      flex-direction: column;
      margin-right: 10px;
    }

    button {
      flex-basis: 10%;
    }
  }
}

.eez-subscription-description {
  margin-top: 10px;

  li {
    font-size: 14px;
  }
}

/*** Suggestion tags ***/

.suggestion-container {
  animation: scrollDown 0.5s ease-in;
}

.eez-suggestion-list {
  display: flex;
  list-style-type: none;
  border: 0;
  margin: 0;
  overflow: hidden;
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  cursor: pointer;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: crimson;
  color: white;
}

.tag:hover::after {
  border-left-color: crimson;
}

.btn-save {
  width: 250px;
  padding: 10px;
}

@keyframes scrollDown {
  0% {
    opacity: 0.25;
    transform: translateY(50px);
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
