.footer {
  padding: 4rem !important;
  // margin-top: 9rem !important;
  background-color: lighten($iuu-dark, 10%);
  color: white;

  .ecotrust-logo {
    max-width: 130px;
    object-fit: contain;
    width: 100%;
  }

  .header {
    font-size: 1.4rem;
  }

  .stackable {
    display: flex;
    flex-wrap: wrap;

    .three {
      flex: 1;

      /* Mobile Only ==============================*/
      @media (max-width: 700px) {
        flex: 100%;
        margin-bottom: 2rem;
      }
    }
  }

  .list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    .item {
      flex: 1 0 100%;
      text-decoration: none;
      font-size: 1.2rem;
      color: darken(white, 25%);
    }

    .item-button {
      color: white;
      padding: 0.5rem;
      text-align: center;
      max-width: 80px;
      border-radius: 5px;
      background-color: $iuu-cta;
    }
  }

  .built-with-love {
    display: block;
    color: #ffffff70;
    font-size: 1.4rem;
    text-align: center;
    margin-top: 4rem;

    a {
      text-decoration: none;
      color: inherit;
    }

    .countable-logo {
      display: inline-block;
      margin-left: 0.6rem;
      max-width: 28px;
    }
  }
}
