
/* GLOBAL */
* {
  box-sizing: border-box;
}

body {
  font-family: PrivaPro;
  margin: 0;
  padding: 0;
  height: 100vh;
}

a {
  text-decoration: none;
}

main {
  min-height: 50vh;
}

.no-scroll {
  overflow: hidden;
}
