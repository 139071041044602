/* MAP */
#map_container {
  height: 100vh;
  display: flex;
  align-content: stretch;
}

#mapbox {
  flex: 1;
  position: relative;
  height: 100%;
}

/* Marker tweaks */
.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  font: 400 15px/22px "Helvetica Neue", Sans-serif;
  padding: 0;
  width: 240px;
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-content h3 {
  background: #91c949;
  color: #fff;
  margin: 0;
  display: block;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
  margin-top: -15px;
}

.mapboxgl-popup-content h4 {
  margin: 0;
  display: block;
  padding: 10px;
  font-weight: 400;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #91c949;
}

.popup-inner {
  max-height: 150px;
  overflow: auto;
}

.map-mention {
  font-size: 1.1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  text-align: center;
  color: #333030;
  width: 100%;
  z-index: 0;

  .map-countable-bottom-link {
    text-decoration: none;
    color: inherit;

    /* Mobile Only ==============================*/
    @media (max-width: 700px) {
      display: none;
    }
  }
  .map-countable-logo {
    max-width: 26px;
  }

  span {
    background: #ffffff30;
    padding: 0.4rem;
    border-top-left-radius: 4.7px;
    border-top-right-radius: 4.7px;
  }
}

@import "./Filters/filters";
