.page {
  .page-image-wrapper {
    /* display: block; */
    height: 35rem;
    margin-top: 4rem;
    background-color: gray;

    margin-bottom: 2.5rem;
    background-image: url("../static/images/page-background-island.png");

    background-size: cover;

    /* Desktop Only ==============================*/
    @media (min-width: 1350px) {
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .ui.divider {
    max-width: 50px;
    margin: 0 auto;
  }

  .page-subtitle {
    font-weight: bold;
    color: lighten($iuu-dark, 40%);
    text-align: center;
    display: block;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    font-style: italic;
  }

  .page-list {
    list-style: none;
    max-width: 500px;
    margin: 0 auto;
    font-size: 1.3rem;
    margin-top: 5rem;

    li {
      position: relative;
      margin-bottom: 1rem;
      color: lighten($iuu-font-gray, 40%);

      i {
        position: absolute;
        top: 4.5px;
        left: 0;
        font-size: 1.1rem;
        color: $iuu-blue;
      }

      .page-highlighted-text {
        color: #3594d3;
        font-weight: bold;
        padding-right: 0.5rem;
        padding-left: 1.8rem;
      }
    }
  }
}
