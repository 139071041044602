
/** Stories-Container **/
.stories-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 0 20% 2% 20%;
  justify-content: center;

  h1{
    text-align: left;
  }
  /**Empty Message **/
  .stories-empty-msg {
    display: flex;
    flex-direction: column;
    align-items: center;

    span > i {
      font-size: 30px;
    }

    .msg-text {
      font-weight: 700;
      font-size: 25px;
    }
  }
}

/** End of Stories-Container **/

/** Article Card Container **/
$accent: #e74c3c;
$white: #ffffff;

.story-card {
  flex: 35%;
  margin-right: 1%;
  margin-bottom: 1%;
  background-color: white;
  box-shadow: 0 8px 16px rgba(black, 0.2);
  cursor: pointer;

  div.card-img-container {
    overflow: hidden;
    position: relative;
  }

  div.card-img {
    transition: 0.45s ease all;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    width: 100%;
    padding: 30px;
    color: white;
    position: relative;

    .date {
      -webkit-font-smoothing: antialiased;
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 1;
      background: $accent;
      width: 55px;
      height: 55px;
      padding: 12.5px 0;
      border-radius: 100%;
      color: $white;
      font-weight: 700;
      text-align: center;
      box-sizing: border-box;
      .day {
        font-size: 14px;
      }
      .month {
        font-size: 10px;
        text-transform: uppercase;
      }
    }

    h1 {
      -webkit-font-smoothing: antialiased;
      font-family: "bodoni-urw";
      font-weight: normal;
      font-size: 36px;
      margin: 0;
      position: relative;
    }
  }

  div.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 220px;
    position: relative;

    .category {
      position: absolute;
      top: -10%;
      left: 0;
      background: #00BACE;
      padding: 10px 15px;
      color: white;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }

    p {
      color: #262626;
      padding: 30px;
      margin: 0;
      font-weight: 300;
      line-height: 24px;
    }

    p > h2 {
      font-weight: 600;
      display: block;
      margin: 0;
    }

    div.card-link {
      // border-top: 1px solid #cdcdcd;
      padding: 0 0 30px;

      a {
        -webkit-font-smoothing: antialiased;
        display: block;
        padding: 0 30px;
        text-decoration: none;
        text-transform: uppercase;
        color: #00BACE;
        font-weight: bold;
        font-size: 14px;
      }

      a:hover {
        color: darken(#2d58c4, 20%);
      }
    }
  }
}

.stories-container div:nth-child(odd){
  .card-img{
      background-image: var(--background);
  }
}

.stories-container div:nth-child(even){
  .card-img{
    background-image: var(--background1);
  }
}

.story-card:hover{
  box-shadow: 0 8px 16px rgba(black, 0.4);
  .card-img {
    background-size: 105% 105%;
  }
} 

.summary-meta {
  display: flex;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  width: 90%;
  margin-bottom: 2%;
  box-sizing: border-box;
  padding-bottom: 1%;
  font-size: 1rem;

  .author {
    margin-right: 2%;
  }
}

/*** END OF LIST OF STORIES ***/

/*** START OF STORY DETAIL ***/
.story-detail-container {
  display: flex;
  flex-direction: column;
  padding: 0 20% 0 20%;
  box-sizing: border-box;

  .story-title {
    margin: 0;
    padding: 0;
    align-self: flex-start;
    font-size: 3rem;
  }

  .story-content {
    font-size: 1.5rem;
    line-height: 2;
    text-indent: 5%;
    padding-bottom: 1%;
  }

  .summary-meta {
    width: 100%;
  }
}
