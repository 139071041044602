.popup-inner {
  -webkit-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.13);

  position: relative;

  .close-btn {
    position: fixed;
    top: -0.5rem;
    right: -3.1px;

    i {
      font-size: 1.4rem;
      color: lighten($iuu-dark, 40%);
    }
  }

  .popup-back-btn {
    border-radius: 5px;
    background: rgba(0, 0, 0, 1);
    color: #fff;
    font-size: 0.8rem;
    padding: 0 10px;
    line-height: 10px;
  }

  .scroll-indicator {
    position: fixed;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    i {
      cursor: pointer;
      font-size: 1.4rem;
      color: lighten($iuu-dark, 40%);
    }
  }
}
.map-incident {
  font-size: 1.2rem;
  line-height: 1;
  border-bottom: 1px solid $iuu-black;

  .map-incident-detail {
    font-weight: bold;
  }
  p {
    padding: 3px 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: $iuu-dark-blue;

    i {
      font-size: 1rem;
      color: $iuu-dark-blue;
    }
  }

  .map-incident-category {
    position: relative;
    top: 1px;
    left: 2px;
    cursor: pointer;

    &.unauthorized {
      color: #ec619c;
    }
    &.species {
      color: #ec613f;
    }
    &.gear {
      color: #f5a04e;
    }
    &.zone {
      color: #ffe123;
    }
    &.quota {
      color: #b6c83c;
    }
    &.labour {
      color: #396ab1;
    }
    &.smuggling {
      color: #8ab2df;
    }
    &.underreporting {
      color: #94376b;
    }
    &.other {
      color: #9b9b9b;
    }
  }
}

.story-icon {
  color: #396ab1;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    transform: scale(1.1);
  }
}

.map-incident-category[data-tooltip],
.story-icon[data-tooltip] {
  position: relative;
}
.map-incident-category[data-tooltip]:hover:after,
.story-icon[data-tooltip]:hover:after {
  content: attr(data-tooltip);
  display: block;
  width: 100px;
  position: absolute;
  z-index: 999;
  padding: 0.5rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  bottom: 1rem;
}
